<template>
  <card-component title="Mudar senha" icon="lock">
    <form @submit.prevent="submit">
      <b-field
        horizontal
        label="Senha atual"
        message="Sua senha atual (obrigatório)"
      >
        <b-input
          v-model="form.password_current"
          name="password_current"
          type="password"
          required
          autcomplete="current-password"
        />
      </b-field>
      <hr />
      <b-field horizontal label="Nova senha" message="Nova senha (obrigatório)">
        <b-input
          v-model="form.password"
          name="password"
          type="password"
          required
          autocomplete="new-password"
        />
      </b-field>
      <b-field
        horizontal
        label="Confirmação de nova senha"
        message="Insira a nova senha novamente (obrigatório)"
      >
        <b-input
          v-model="form.password_confirmation"
          name="password_confirmation"
          type="password"
          required
          autocomplete="new-password"
        />
      </b-field>
      <hr />
      <b-field horizontal>
        <div class="control">
          <button
            type="submit"
            class="button is-primary"
            :class="{ 'is-loading': isLoading }"
          >
            Gravar
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import { post } from '../utils/api'

export default {
  name: 'PasswordUpdateForm',
  components: {
    CardComponent
  },
  data () {
    return {
      isLoading: false,
      form: {
        password_current: null,
        password: null,
        password_confirmation: null
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true
      post('sessions/changePassword', {
        password: this.form.password_current,
        newPassword: this.form.password,
        newPasswordConfirmation: this.form.password_confirmation
      }).then(() => {
        this.isLoading = false
        this.$buefy.snackbar.open(
          {
            message: 'Senha atualizada com sucesso',
            queue: false
          },
          500
        )
      }).catch((err) => {
        this.isLoading = false
        this.$buefy.dialog.alert({
          title: 'Erro no fechamento do ticket',
          message: err.response.data.message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    }
  }
}
</script>
